import {hitGoogleTagManager} from "./init_google_tag_manager";

type EventTypeHeroBanner = "hero_baner_show" | "hero_baner_click";

interface IGtmHeroBanner {
    event: EventTypeHeroBanner;
}

/**
 * When show hero banner on view
 */
export const gtmHeroBanerShow = (): boolean => {
    const gtmData: IGtmHeroBanner = {
        event: "hero_baner_show"
    };
    hitGoogleTagManager(gtmData);
    return true;
};

/**
 * When you click on hero banner
 */
export const gtmHeroBanerClick = (): boolean => {
    const gtmData: IGtmHeroBanner = {
        event: "hero_baner_click"
    };
    hitGoogleTagManager(gtmData);
    return true;
};
